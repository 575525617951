import React from 'react'
import "../css/collection.css"
const Collection = () => {
  return (
    <div className='container-fluid'>
        <div className='container'>
 <div className='collection-main'>
    <h1 className='collection-heading'>A Collection Of Recently Completed Projects</h1>
    <div className='collection-num-para-div'>
<div className='num-para-div'>
    <h1 className='collection-number'>299</h1>
    <p className='collection-para'>Projects Finished</p>
</div>
<div  className='num-para-div'>
    <h1 className='collection-number'>200+</h1>
    <p className='collection-para'>Happy Clients</p>
</div>
<div  className='num-para-div'>
    <h1 className='collection-number'>20</h1>
    <p className='collection-para'>Projects In Progress</p>
</div>
    </div>
 </div>
  </div>
  </div>)
}

export default Collection