
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer'
import { Route, Routes, BrowserRouter, HashRouter } from 'react-router-dom';
import AboutUs from './pages/AboutUs'
import Portifolio from './pages/Portifolio';
import { useEffect } from 'react';


function App() {

 
  return (
    <>
      <div className='allpages'>
        <HashRouter>
          <div className='sticky-top'>
            <Navbar />
          </div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/portfolio' element={<Portifolio />} />
          </Routes>
          <Footer />
        </HashRouter>
      </div>
    </>
  );
}

export default App;
