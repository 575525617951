import React from 'react'
import '../css/header.css'
import { ArrowRight } from 'react-bootstrap-icons';

const Header = () => {
  return (
    <>
      <div className='container-fluid '>
        <div className='container'>
          <div className='header-container-main'>
            <div className='header-container-vedic-main-top   header-bg-images'>
              <div className='header-container-vedic'>
                <div className='header-heading'>
                  <h1 className='header-heading-elevate-txt'>Elevate Your Space with Timeless Design</h1>
                </div>
                <div className='header-para'>
                  <p>Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality.</p>
                </div>
                <div className='header-btn-div'>
                  <div>
                    <button className='getProposal-btn'>Get a Free Proposal</button>
                  </div>
                  <div>
                    <button className='checkWork-btn'>Check work
                      <ArrowRight className='arrow-btn' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='header-container'>
              <div className='header-about-main row'>
                <div className='header-about-left col-md-6 '>
                  <h4 className='header-about-left-top-text'>ABOUT</h4>
                  <h1 className='header-about-left-heading'>Vedic Architecture & Interiors</h1>
                  <h5 className='header-heading'>Harmonizing Spaces with Timeless Wisdom</h5>
                  <p className='header-about-left-para'>Welcome to Vedic Architecture, where ancient wisdom seamlessly meets contemporary design. Rooted in the rich heritage of Vedic principles, our approach goes beyond mere aesthetics – it's a journey dedicated to crafting spaces that resonate with balance, harmony, and positive energy.</p>
                  <h5 className='header-heading'>The Essence of Vedic Architectures</h5>
                  <p className='header-about-left-para'>At Vedic Architecture, we find inspiration in ancient Indian scriptures, the Vedic texts. These timeless principles guide our designs, focusing on creating spaces that enhance the overall well-being of individuals in both their living and working environments.</p>
                </div>
                <div className='header-about-right col-md-6'>
                  <div >
                    <img className='black-image' src='../images/vedic-home-img.jpeg' alt='' />
                  </div>
                </div>
              </div>
              <div className='header-keyElements-container'>
                <div>
                  <h2 className='keyElements-heading'>Key Elements of Vedic Architecture</h2>
                  <div className='keyElements-bottom-container row'>
                    <div className='keyElements-bottom-main row'>
                      <div className='col-md-4'>
                        <img className='keyElements-bottom-main-images' src='../images/vastu-image.svg' alt='' />
                        <h5 className='keyElements-bottom-main-heading-text'>Vastu Shastra</h5>
                        <p className='keyElements-bottom-main-para'>Our designs incorporate Vastu Shastra, an ancient Indian science of architecture, which focuses on the harmonious arrangement of elements to enhance positive energy flow within a space.</p>
                      </div>
                      <div className='col-md-4'>
                        <img className='keyElements-bottom-main-images' src='../images/Natural-image.svg' alt='' />
                        <h5 className='keyElements-bottom-main-heading-text'>Natural Elements</h5>
                        <p className='keyElements-bottom-main-para'>We embrace the elements of nature, integrating natural light, ventilation, and sustainable materials to create spaces that are not only visually pleasing but also contribute to the well-being of the occupants.</p>
                      </div>
                      <div className='col-md-4'>
                        <img className='keyElements-bottom-main-images' src='../images/sacred-image.svg' alt='' />
                        <h5 className='keyElements-bottom-main-heading-text'>Sacred Geometry</h5>
                        <p className='keyElements-bottom-main-para'>Vedic Architectures often incorporate sacred geometry, which is believed to bring a sense of order and harmony to the built environment. The precision in design reflects the cosmic order found in the universe.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='header-ourprocess-div'>
              <h4 className='header-ourprocess-heading'>OUR PROCESS</h4>
              <h1 className='streamlined-heading'>Streamlined Design Journey: Our Three-Step Process</h1>
              <p className='discover-para'>Discover the perfect blend of innovation and elegance.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header