import React from 'react'
import "../css/projects.css"
const Projects = () => {
    return (
        <div className='container-fluid'>
            <div className='container'>
                <div className='projects-main-div'>
                    <div className='projects-top-div row'>
                        <h4 className='projects-heading '>PROJECTS</h4>
                        <h1 className='a-collecttion-heading-project'>A Collection Of Recently Completed Projects</h1>
                        <p className='discover-para'>Discover the perfect blend of innovation and elegance.</p>
                    </div>
                    <div className='project-img-collection  '>
                        <div className='projectimg1-div'>
                            <div className='project-img-content' >

                                <img src='../images/vedic-projects-img1.jpeg' className='proj-img' />

                                <div className='project-content-div'>
                                    <div className='project-contemporary-discover-div'>
                                        <h1 className='project-contemporary-heading'>CONTEMPORARY LUXURY VILLA</h1>
                                        <p className='project-discover-para'>Discover the perfect blend of innovation and elegance.</p>
                                    </div>
                                    <div className='project-content-hover-div'>
                                        <p> <span className='proj-location'>Location:</span><label className='proj-hyd'>Hyderabad,Telangana</label></p>
                                        <p className='project-building-status-hover'> <span className='proj-location'>Building Area :</span><label className='proj-hyd'>1500sq.ft</label></p>
                                        <p className='project-building-status-hover'> <span className='proj-location'>Project Status : </span><label className='proj-hyd'>Ongoing</label></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='projectimg1-div'>
                            <div className='project-img-content'>
                                <img src='../images/vedic-projects-img2.jpeg' className='proj-img' />
                                <div className='project-content-div'>
                                    <div className='project-contemporary-discover-div'>
                                        <h1 className='project-contemporary-heading'>CONTEMPORARY LUXURY VILLA</h1>
                                        <p className='project-discover-para'>Discover the perfect blend of innovation and elegance.</p>
                                    </div>
                                    <div className='project-content-hover-div'>
                                        <p> <span className='proj-location'>Location:</span><label className='proj-hyd'>Hyderabad,Telangana</label></p>
                                        <p className='project-building-status-hover'> <span className='proj-location'>Building Area :</span><label className='proj-hyd'>1500sq.ft</label></p>
                                        <p className='project-building-status-hover'> <span className='proj-location'>Project Status : </span><label className='proj-hyd'>Ongoing</label></p>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='projectimg1-div col'>
                            <div className='project-img-content' >
                                <img src='../images/vedic-projects-img3.jpeg' className='proj-img' />
                                <div className='project-content-div'>
                                    <div className='project-contemporary-discover-div'>
                                        <h1 className='project-contemporary-heading'>CONTEMPORARY LUXURY VILLA</h1>
                                        <p className='project-discover-para'>Discover the perfect blend of innovation and elegance.</p>
                                    </div>
                                    <div className='project-content-hover-div'>
                                        <p> <span className='proj-location'>Location:</span><label className='proj-hyd'>Hyderabad,Telangana</label></p>
                                        <p className='project-building-status-hover'> <span className='proj-location'>Building Area :</span><label className='proj-hyd'>1500sq.ft</label></p>
                                        <p className='project-building-status-hover'> <span className='proj-location'>Project Status : </span><label className='proj-hyd'>Ongoing</label></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Projects