import React, { useEffect, useState } from 'react'
import "../css/aboutus.css"
import { useLocation } from 'react-router-dom';
import axios from "axios"
const AboutUs = () => {



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    Email: "",
    Enquiry: "",
    Location: "",
    projects: "",
    DesksRequired: ""
  });

  const [errors, setErrors] = useState({});

  const Email = (e) => {
    e.preventDefault()
  }

  const FormDetails = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const res = await axios.post("http://localhost:8084/contact",
          details,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setDetails({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          Email: "",
          Enquiry: "",
          Location: "",
          projects: "",
          DesksRequired: ""
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!details.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!details.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required";
    }
    if (!details.Enquiry.trim()) {
      newErrors.Enquiry = "Enquiry is required";
    }
    if (!details.Location.trim()) {
      newErrors.Location = "Desk Required is required";
    }
    if (!details.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!details.Email.trim()) {
      newErrors.Email = " Email is required";
    } else if (!/\S+@\S+\.\S+/.test(details.Email)) {
      newErrors.Email = "Invalid email address";
    }
    if (!details.projects.trim()) {
      newErrors.projects = "project is required";
    }
    if (!details.DesksRequired.trim()) {
      newErrors.DesksRequired = "DesksRequired is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToContact) {
      const contactSection = document.getElementById('contactus');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (

    <>
      <div className='container-fluid'>
        <div className='container'>
          <div className='aboutus-container-main'>
            <div className='aboutus-main'>
              <div className='aboutus-top-main'>
                <h1 className='aboutus-top-main-heading'>ABOUT US</h1>
                <div className='aboutus-top-inner row'>
                  <div className='aboutus-top-left '>
                    <h2 className='aboutus-top-left-heading'>Vedic Architecture & Interiors</h2>
                    <p className='aboutus-top-left-para1'>Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality. Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality.</p>
                    <p className='aboutus-top-left-para2'>Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality.</p>
                  </div>
                  <div className='aboutus-top-right '>
                    <img className='aboutus-top-right-image' src='../images/aboutus-header-image.svg' alt='image' />
                  </div>
                </div>
              </div>
              <div className='aboutus-keyElements-container'>

                <h2 className='aboutus-keyElements-heading'>Key Elements of Vedic Architecture</h2>
                <div className='aboutus-keyElements-bottom-container '>
                  <div className='aboutus-keyElements-bottom-main row'>
                    <div className='col-md-4 col-sm-12'>
                      <img className="aboutus-keyElements-images" src='../images/vastu-image.svg' alt='' />
                      <h5 className='aboutus-keyElements-bottom-main-heading-text'>Vastu Shastra</h5>
                      <p className='aboutus-keyElements-bottom-main-para'>Our designs incorporate Vastu Shastra, an ancient Indian science of architecture, which focuses on the harmonious arrangement of elements to enhance positive energy flow within a space.</p>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                      <img className="aboutus-keyElements-images" src='../images/Natural-image.svg' alt='' />
                      <h5 className='aboutus-keyElements-bottom-main-heading-text'>Natural Elements</h5>
                      <p className='aboutus-keyElements-bottom-main-para'>We embrace the elements of nature, integrating natural light, ventilation, and sustainable materials to create spaces that are not only visually pleasing but also contribute to the well-being of the occupants.</p>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                      <img className="aboutus-keyElements-images" src='../images/sacred-image.svg' alt='' />
                      <h5 className='aboutus-keyElements-bottom-main-heading-text'>Sacred Geometry</h5>
                      <p className='aboutus-keyElements-bottom-main-para'>Vedic Architectures often incorporate sacred geometry, which is believed to bring a sense of order and harmony to the built environment. The precision in design reflects the cosmic order found in the universe.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className='aboutus-founder-main'>
                <div className='aboutus-founder-main-inner row'>
                  <div className='aboutus-founder-inner-left col-md-6'>

                    <img className='black-img' src='../images/black.svg' alt='' />

                  </div>
                  <div className='aboutus-founder-inner-right col-md-6'>
                    <h3 className='aboutus-founder-inner-right-heading1'>J.VENI</h3>
                    <h4 className='aboutus-founder-inner-right-heading2'>Founder, Vedic Architect</h4>
                    <p className='aboutus-founder-inner-right-para1'>Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality. Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality.</p>
                    <p className='aboutus-founder-inner-right-para2'>Discover the perfect blend of innovation and elegance. Our architects and designers craft spaces that tell your unique story. From concept to creation, let us transform your vision into reality.</p>
                  </div>
                </div>
              </div>

              <div className='contactus-main' id='contactus'>
                <div className='contactus-main-inner' >
                  <h1 className='contactus-main-inner-top-heading'>Contact Us</h1>
                  <div className='contactus-main-inner-top'>

                    <p className='contactus-main-inner-top-para'>Whether you're looking to transform your home, office, or a specialized space,Vedic Architecture is ready to embark on this design journey with you. Collaborate with us, and let's create spaces that resonate with your unique story.</p>
                  </div>
                  <div className='contactus-main-inner-bottom'>
                    <form onSubmit={Email}>
                      <div className='contactus-main-inner-bottom-form row'>
                        <div className='contactus-main-inner-bottom-form-left col-md-6 '>
                          <div className="contactus-main-inner-bottom-form-field for mb-3">
                            <label for="exampleInputPassword1" className="form-label">First Name<span className='contactus-star'>*</span></label>
                            <div>
                              <input type='text' name="firstName" value={details.firstName} placeholder='First Name' onChange={FormDetails} id=' contactus-desks-div-input ' className={errors.firstName ? "error" : "input-ele"} />
                            </div>

                          </div>
                          <div className="contactus-main-inner-bottom-form-field for mb-3">
                            <label for="exampleInputPassword1" className="form-label">Phone Number<span className='contactus-star'>*</span></label>
                            <input
                              value={details.phoneNumber}
                              placeholder="Enter your Mobile Number"
                              name="phoneNumber"
                              onChange={FormDetails}

                              onKeyDown={(e) => {
                                if (
                                  !(e.key === "Backspace" || e.key === "Delete") &&
                                  !/[\d]/.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                              className={errors.phoneNumber ? "error" : "input-ele"}
                              
                            />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label for="exampleInputPassword1" className="form-label">Enquiry for<span className='contactus-star'>*</span></label>
                            <input className={errors.Enquiry ? "error" : "input-ele"}  type='text' name="Enquiry" value={details.Enquiry} placeholder='Enquiry' onChange={FormDetails} />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label for="exampleInputPassword1" className="form-label">Location*</label>
                            <input className={errors.Location ? "error" : "input-ele"} id=' contactus-desks-div-input ' type='text' name="Location" value={details.Location} placeholder='Location' onChange={FormDetails} />
                          </div>
                        </div>
                        <div className='contactus-main-inner-bottom-form-right col-md-6 '>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label for="exampleInputPassword1" className="form-label">Last Name<span className='contactus-star'>*</span></label>
                            <input className={errors.lastName ? "error" : "input-ele"}  type='text' name="lastName" value={details.lastName} placeholder='Last Name' onChange={FormDetails} />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label for="exampleInputPassword1" className="form-label">Email<span className='contactus-star'>*</span></label>
                            <input className={errors.Email ? "error" : "input-ele"}  type='text' name="Email" value={details.Email} placeholder=' Email' onChange={FormDetails} />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label for="exampleInputPassword1" className="form-label">Project’s per sq.ft</label>
                            <input className={errors.projects ? "error" : "input-ele"}  type='text' name="projects" value={details.projects} placeholder='Project’s per sq.ft' onChange={FormDetails} />
                          </div>
                        </div>
                        <div className="contactus-desks-div mb-3">
                          <label for="exampleInputPassword1" className="form-label">Desks Required</label>
                          <div className=' '>
                            <textarea id='desk' className={errors.DesksRequired ? "error" : "input-ele"} type='text' name="DesksRequired" value={details.DesksRequired} placeholder='How desks required for workspace.' onChange={FormDetails} ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className='contactus-submit-btn-div'>
                        <button type="submit" className='contactus-submit-btn' onClick={handleSubmit}>Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

      </div>

    </>
  )
}

export default AboutUs