import React, { useState } from 'react';
import "../css/portifolio.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import SwiperNavButton from './SwiperNavButton';
const Portifolio = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const projects = [
        {
            heading: "CONTEMPORARY LUXURY VILLA",
            para: "Discover the perfect blend of innovation and elegance.",
            location: "Hyderabad, Telangana",
            BuildingArea: "1500sq.ft",
            ProjectStatus: "Ongoing",
            imgs: [
                '../images/project1-img-01.jpeg',
                '../images/project1-img-02.jpeg',
                '../images/project1-img-03.jpeg'
            ]
            ,
            project: "Project-1"
        },
        {
            heading: "CONTEMPORARY LUXURY VILLA",
            para: "Discover the perfect blend of innovation and elegance.",
            location: "Hyderabad, Telangana",
            BuildingArea: "1500sq.ft",
            ProjectStatus: "Ongoing",
            imgs: [
                '../images/project2-img-01.jpeg',
                '../images/project2-img-02.jpeg',
                '../images/project2-img-03.jpeg'
            ]
            ,
            project: "Project-2"
        },
        {
            heading: "CONTEMPORARY LUXURY VILLA",
            para: "Discover the perfect blend of innovation and elegance.",
            location: "Hyderabad, Telangana",
            BuildingArea: "1500sq.ft",
            ProjectStatus: "Ongoing",
            imgs: [
                '../images/project3-img-01.jpeg',
                '../images/project3-img-02.jpeg',
                '../images/project3-img-03.jpeg'
            ]
            ,
            project: "Project-3"
        }
    ];
    const PortifolioSlide = ({ project, index }) => {
        const [bigImg, setBigImg] = useState(project.imgs[0]);
        const [selectedImg, setSelectedImg] = useState(project.imgs[0]);
        const handleClick = (newImg) => {
            setBigImg(newImg);
            setSelectedImg(newImg);
        };
        return (
            <div className='container-fluid portfolio-main-container'>
                <div className=''>
                    <div className='portifolio-main'>
                        <div className='portifolio-all-imgs'>
                            <div className='portifolio-blur-img-container-div' >
                                <div>
                                    <img src={bigImg} className='portifolio-big-img' />
                                </div>
                                <div className='portifolio-blur-container'>
                                    <div className='portifolio-comtemporary-discover-div'>
                                        <h1 className='portifolio-comtemporary'>{project.heading}</h1>
                                        <p className='portifolio-discover'>{project.para}</p>
                                    </div>
                                    <div className='portifolio-blur-inside-container'>
                                        <p className='portifolio-location-build-proj'><span>Location:</span><label className='portifolio-location-build-proj-right'>{project.location}</label></p>
                                        <p className='portifolio-location-build-proj'> <span >Building Area :</span><label className='portifolio-location-build-proj-right'>{project.BuildingArea}</label></p>
                                        <p className='portifolio-location-build-proj'> <span >Project Status : </span><label className='portifolio-location-build-proj-right'>{project.ProjectStatus}</label></p>
                                    </div>
                                </div>
                            </div>
                            <div className='portifolio-imgs-div'>
                                {project.imgs.map((imgUrl, imgIndex) => (
                                    <img
                                        key={imgIndex}
                                        src={imgUrl}
                                        className={selectedImg === imgUrl ? 'portifolio-right-img selected' : 'portifolio-right-img'}
                                        onClick={() => handleClick(imgUrl)}
                                        alt={`Project ${index + 1} Image ${imgIndex + 1}`}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
    return (
        <>
            <div className='container-fluid portfolio-main-container'>
                <div className=''>
                    <h1 className='portifolio-heading text-center'>OUR PROJECTS</h1>
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper" onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}>
                        {projects.map((project, index) => (
                            <SwiperSlide key={index}>
                                <PortifolioSlide project={project} index={index} />
                            </SwiperSlide>
                        ))}
                        <div className='pagenation-btn'>
                            <SwiperNavButton projects={projects.map(val => val.project)} activeIndex={activeIndex} />
                        </div>
                    </Swiper>
                </div>
            </div>

        </>
    );
};

export default Portifolio;