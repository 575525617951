import React, { useEffect, useState } from 'react'
import "../css/Navbar.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'
const Navbar = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [show, setShow] = useState(false)
  const toggleNavbar = () => {
    setShow(!show);
  };
  const closeNavbar = () => {
    setShow(false);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const navigateToContactus = () => {
    closeNavbar(); // Close the navbar
    if (window.innerWidth < 992) {
      // Add event listener to close navbar when clicking on any link
      document.querySelectorAll('.nav-item').forEach(item => {
        item.addEventListener('click', closeNavbar);
      });
    } else {
      // Remove event listener for clicking on navbar items
      document.querySelectorAll('.nav-item').forEach(item => {
        item.removeEventListener('click', closeNavbar);
      });
    }

    // Navigate to the "About Us" page
    navigate("/about-us", { state: { scrollToContact: true } });
  }

  return (
    <>
      <div className='container-fluid navbar-main-container'>
        <div className='container'>
          <nav className="navbar navbar-expand-lg  ">
            <img src='../images/vedic-logo.svg' className='navbar-vedic-logo ' />
            <button className="navbar-toggler" onClick={toggleNavbar} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={show ? "true" : "false"} aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse  ${show ? 'show' : ''}`} id="navbarSupportedContent"  >
              <ul className="navbar-nav navbar-right-list w-100 d-flex justify-content-end mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/" onClick={closeNavbar} >Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active" to="/about-us" onClick={closeNavbar}>About us</Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link active" to="/portfolio" onClick={closeNavbar}>Portfolio</Link>
                </li>
                <li>
                  <button className="btn contact-btn" onClick={navigateToContactus}>Contact us</button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Navbar
