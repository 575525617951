import React, { useEffect, useRef, useState } from "react";
import "../css/ourprocess.css";
const OurProcess = () => {

    return (
        <>
            <div className="container-fluid">
                <div className=" ourprocess-container">

                    <div className="ourprocess-scroll-part-main ">
                        <div className='ourprocess-scrolling-div'>
                            <div className='number-client-core-execute-main-div-one' >

                                <div className='ourprocess-number-image' >
                                    <img className='ourprocess-number-fixed-image' src="../images/01.svg" alt='image not found' />
                                </div>

                                <div className='client-core-execute-div'>
                                    <h1 className='client-core-execute-heading'>CLIENT DISCUSSION</h1>
                                    <img className="ourprocess-images" src="../images/client.svg" alt='image not found' />
                                    <p className='client-core-execute-para'>We start by talking with you. Your insights and vision guide us.Share your thoughts, preferences, and dreams for your space. We're here to listen.</p>
                                </div>
                            </div>
                            <div className='number-client-core-execute-main-div-one'>
                                <div className='ourprocess-number-image' >
                                    <img className='ourprocess-number-fixed-image' src="../images/02.svg" alt='image not found' />
                                </div>


                                <div className='client-core-execute-div'>
                                    <h1 className='client-core-execute-heading'>CORE CONCEPT</h1>
                                    <img className="ourprocess-images" src='../images/coreconcept.svg' alt='image not found' />
                                    <p className='client-core-execute-para'>Next, we create a core concept based on our discussions. We'll provide visual representations like sketches and mood boards, ensuring our design aligns with your expectations. </p>
                                </div>


                            </div>
                            <div className='number-client-core-execute-main-div-one'>
                                <div className='ourprocess-number-image' >
                                    <img className='ourprocess-number-fixed-image' src="../images/03.svg" alt='image not found' />
                                </div>
                                <div className='client-core-execute-div'>
                                    <h1 className='client-core-execute-heading'>EXECUTION</h1>
                                    <img className="ourprocess-images" src='../images/execute.svg' alt='image not found' />
                                    <p className='client-core-execute-para'>Once we've nailed down the perfect concept, it's time to bring it to life. Our team meticulously plans and executes the design, turning it into a tangible, beautiful space for you to enjoy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></div></>
    )
}

export default OurProcess;