import React from 'react'
import "../css/testimonial.css"
const Testimonials = () => {
    return (

        <div className='container-fluid'>

            <div className='testimonial-main-container row'>
                <div className='testimonial-top-container col-lg-06 col-sm-12 col-md-06'>
                    <h1 className='testimonial-main-heading'>TESTIMONIAL</h1>
                    <h1 className='testimonial-heading' >What Our Customers Say</h1>
                    <p className='testimonial-para'>Discover the perfect blend of innovation and elegance.</p>
                </div>


                <div className='testimonial-body-container  col-lg-06 col-sm-12 col-md-06'>
                    <div className='testimonial-body-left-container'>
                        <img src='../images/inverted-comma.svg' className='testimonial-inverted-comma-img' />
                        <p className='testimonial-body-para pt-4' >We would like to express our utmost satisfaction with the service  provided by Vedic</p>
                    </div>
                    <div className='test'>
                        <div className='testimonial-body-right-container'>
                            <div className="card testimonial-card" >
                                <img src="../images/testimonial.svg" className="card-img-top" alt="..." />

                                <p className="card-text testimonial-discover">Discover the perfect blend of innovation <br />and elegance</p>
                                <h5 class="card-title testimonial-contemporary">CONTEMPORARY LUXURY VILLA</h5>

                            </div>
                            <div className="card testimonial-card" >
                                <img src="../images/testimonial.svg" className="card-img-top" alt="..." />

                                <p className="card-text testimonial-discover">Discover the perfect blend of innovation <br />and elegance</p>
                                <h5 class="card-title testimonial-contemporary">CONTEMPORARY LUXURY VILLA</h5>

                            </div>
                            <div className="card testimonial-card" >
                                <img src="../images/testimonial.svg" className="card-img-top" alt="..." />

                                <p className="card-text testimonial-discover">Discover the perfect blend of innovation <br />and elegance</p>
                                <h5 class="card-title testimonial-contemporary">CONTEMPORARY LUXURY VILLA</h5>

                            </div>
                            <div className="card testimonial-card" >
                                <img src="../images/testimonial.svg" className="card-img-top" alt="..." />
                                <p className="card-text testimonial-discover">Discover the perfect blend of innovation <br />and elegance</p>
                                <h5 class="card-title testimonial-contemporary">CONTEMPORARY LUXURY VILLA</h5>
                            </div>

                        </div>
                    </div>

                </div>

            </div>


        </div>


    )
}

export default Testimonials