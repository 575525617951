import React from 'react'
import "../css/footer.css"
import { SlSocialInstagram } from "react-icons/sl";
import { SiTwitter } from "react-icons/si";
import { FaFacebook } from "react-icons/fa6";
import { ImGithub } from "react-icons/im";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

    <div className='container-fluid Footer-main'>
      <div className='container'>
        <div className='Footer-main'>
          <div className='Footer-left'>
            <img src='../images/vedic-logo.svg' className='footer-vedic-logo' />
            <h1 className='footer-left-heading'>Crafting timeless spaces that echo your narrative and style.</h1>
          </div>

          <div className='Footer-right'>

            <div className='Footer-right-left-ul'>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item footer-quick'>Quick Links</li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/"> Home</Link></li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/about-us"> About us</Link></li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/portfolio"> Portfolio</Link></li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/"> Contact us</Link></li>
              </ul>
            </div>
            <div className='Footer-right-right-ul footer-right-items'>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item'>Phone</li>
                <li className='footer-li-items'>+91 1234567890</li>
                <li className='footer-li-items'>+91 1234567890</li>
              </ul>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item'>Email</li>
                <li className='footer-li-items'>vedic@gmail.com</li>
              </ul>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item'>Contact Info</li>
                <li className='footer-li-items address'>303B, Capital Park, Madhapur, Telangana Hyd-50081</li>
              </ul>
            </div>

          </div>

        </div>
        <div className='bottom-div'>
          <div className='bottom-line' ></div>
          <div className=' footer-bottom-container'>
            <p className='footer-copyright-para'>© Copyright 2024, All Rights Reserved by AbilioIt Solutions</p>
            <div className='footer-icons-container  '>
              <a href="https://twitter.com/?lang=en-in" target="_blank" > <SiTwitter className='footer-icons' /></a>
              <a href='https://www.facebook.com/' target="_blank">   <FaFacebook className='footer-icons' /></a>
              <a href="https://www.instagram.com/?hl=en" target="_blank"><SlSocialInstagram className='footer-icons' /></a>
              {/* <a href=" https://github.com/" target="_blank" > <ImGithub className='footer-icons' /></a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer